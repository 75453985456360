var SearchForm = function($element, options){
    try {
        this.element = $element;

        this.defaults = {
            baseUrl: '',
            search: '',
        };

        this.options = jQuery.extend({}, this.defaults, options);
        this.init();    
    } catch (error) {
        console.error(error);
    }
    
};

SearchForm.prototype = {
    defaults: {},
    currentPages: {},
    lastPages: {},
    waiting: false,
    init: function ()
    {
        var _self = this;

        this.element.find('.search-tab').each(function() {
            var tab = $(this).data('tab');
            var lastPageItem = $(this).find('[data-role="pagination"][data-type="number"]').last();
            
            _self.currentPages[tab] = 1;
            if (lastPageItem.length === 1) {
                _self.lastPages[tab] = parseInt(lastPageItem.data('page'));
            }
        });

        this.addDOMEvents();
    },
    addDOMEvents: function() {
        var _self = this;

        this.element.find('[data-role="pagination"]').on('click', function() {
            if (!_self.waiting && !$(this).hasClass('active') && !$(this).hasClass('inactive')) {
                var content = $(this).parents('.search-tab');
                var tab = content.data('tab');
                var type = $(this).data('type');
                var page = _self.currentPages[tab];

                switch (type) {
                    case 'number':
                        page = parseInt($(this).data('page'));
                        break;
                    case 'button':
                        page += parseInt($(this).data('value'));
                        break;
                }

                _self.loadContent(tab, page)
            }
        });
    },
    loadContent: function(tab, page) {
        if (!this.waiting) {
            var _self = this;
            this.waiting = true;

            $.ajax({
                url: _self.options.baseUrl + '/ajax_get_page',
                dataType: 'json',
                type: 'POST',
                data: {
                    type: tab,
                    search: _self.options.search,
                    page: page
                },
                success: function(response) {
                    var container = _self.element.find('.search-tab[data-tab="' + tab + '"]');
                    container.find('[data-role="refreshing-page-content"]').html(response.html);

                    _self.updatePagination(tab, page);

                    $('html, body').animate({
                        scrollTop: $('[data-purpose="tab-container"]').offset().top
                    }, 200);

                    setTimeout(function() {
                        _self.waiting = false;
                    }, 500)
                }
            });
        }
    },
    updatePagination: function(tab, page) {
        var container = this.element.find('.search-tab[data-tab="' + tab + '"]');
        var pagerArea = container.find('.pager-area');
        var prevButton = pagerArea.find('.prev-button');
        var nextButton = pagerArea.find('.next-button');
        
        pagerArea.find('.number').removeClass('active');
        pagerArea.find('.number[data-page="' + page + '"]').addClass('active');

        this.currentPages[tab] = page;

        if (1 < page && prevButton.hasClass('inactive')) {
            prevButton.removeClass('inactive');
        } else if (1 >= page && !prevButton.hasClass('inactive')) {
            prevButton.addClass('inactive');
        }

        if (page >= this.lastPages[tab] && !nextButton.hasClass('inactive')) {
            nextButton.addClass('inactive');
        } else if (page < this.lastPages[tab] && nextButton.hasClass('inactive')) {
            nextButton.removeClass('inactive');
        }
    }
};