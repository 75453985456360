var oldZone = null;

$(document).ready(function(){
    if (typeof streetNames !== 'undefined') {
        $('#street').autocomplete({
            minLength: 2,
            source: streetNames,
            appendTo: $('#streetCol'),
            select: function (event, ui) {
                handleStreetSelect(ui.item.label);
            }
        }).autocomplete('widget').addClass('clearence-streets-autocomplete');
    }

    $('#street').on('blur', function() {
        handleStreetSelect($(this).val());
        
    });

    $('[data-purpose="has-electronic-radio"]').on('change', function() {
        var value = parseInt($(this).val());

        if (value === 1) {
            $('[data-purpose="electronic-details"]').slideDown();
        } else {
            $('[data-purpose="electronic-details"]').slideUp();
        }
    });

    $('#clearenceForm').on('submit', function(e) {
        e.preventDefault();
        sendClearenceForm($(this));
    });

    $('[data-purpose="clearence-form-btn"]').on('click', function() {
        $(this).parents('form').submit();
    })
});

function handleStreetSelect(value)
{
    var validStreet = false;
    var street = null;
    var streets = zoneOptions.filter(function(item) {
        return item.name.toLowerCase() === value.toLowerCase();
    });

    if (streets && streets.length > 0) {
        street = streets[0];
        validStreet = null === street.info;
        
        if (!validStreet) {
            $('[data-purpose="error-content"][data-field="street"]').addClass('active');
            $('[data-purpose="error-content"][data-field="street"]').html(street.info);
        }
    }
    
    
    if (validStreet && street) {
        $('[data-purpose="error-content"][data-field="street"]').removeClass('active');
        $('[data-purpose="error-content"][data-field="street"]').html('');
        loadClearenceDates(street);
    } else {
        $('#clearenceDate').find('option').remove();
        $('#clearenceDate').attr('disabled', true);

        if (!street) {
            $('[data-purpose="error-content"][data-field="street"]').removeClass('active');
            $('[data-purpose="error-content"][data-field="street"]').html('');
        }
    }
}

function loadClearenceDates(street) {
    
    var zone = street.zone;
    $('#clearenceForm').find('[name="zone"]').val(zone);

    if (zone === oldZone) {
        return;
    }
    oldZone = zone;

    $('#clearenceDate').find('option').remove();
    $('#clearenceDate').attr('disabled', true);

    $.ajax({
        url:ajax_controller+'get_clearence_dates',
        dataType:'json',
        type:'post',
        data: {
            zone: zone
        },
        beforeSend: function() {
            $('[data-purpose="error-content"][data-field="date"]').removeClass('active');
            $('[data-purpose="error-content"][data-field="date"]').html('');
        },
        success: function(response) {
            if (!response.success) {
                $('[data-purpose="error-content"][data-field="date"]').addClass('active');
                $('[data-purpose="error-content"][data-field="date"]').html(response.message);
            }
            if (response.dates.length > 0) {
                $('#clearenceDate').removeAttr('disabled');
                $('#clearenceDate').find('option').remove();
                $('#clearenceDate').append('<option value="0">'+clearenceTexts.pleaseChoose+'</option>');

                for (var i = 0; i < response.dates.length; i++) {
                    $('#clearenceDate').append('<option value="'+response.dates[i]+'">'+response.dates[i]+'</option>');        
                }
            }
        }
    });
}

var sendedForm = false;
function sendClearenceForm(form) {
    if (!sendedForm) {
        sendedForm = true;

        $.ajax({
            url:ajax_controller+'send_clearence_form',
            dataType:'json',
            type:'post',
            data: form.serialize(),
            beforeSend: function() {
                form.find('[data-purpose="error-content"]').removeClass('active');
                form.find('.invalid').removeClass('invalid');
            },
            success: function(response) {
                var feedbackContainer = form.find('[data-purpose="feedback"]');
            
                if (response.success) {
                    $('#clearenceDate').find('option').remove();
                    $('#clearenceDate').attr('disabled', true);
                    form.get(0).reset();
                } else {
                    var fields = Object.keys(response.errors);
                    if (fields.length > 0) {
                        $.each(response.errors, function(field, msg){
                            var input = form.find('[name="'+field+'"]');
                            
                            if (input.length) {
                                input.addClass('invalid');
                                var parent = input.parent();
                                if (input.is('select')) {
                                    parent = input.parent().parent();
                                }

                                if (parent.hasClass('input-cont')) {
                                    parent.addClass('invalid');
                                }
                            }
                            if (msg.trim()) {
                                var errorContainer = $('[data-purpose="error-content"][data-field="'+field+'"]');
                                
                                if (errorContainer.length) {
                                    errorContainer.html(msg);
                                    errorContainer.addClass('active');
                                }
                            }
                        });
                    }
                }

                if (feedbackContainer && response.message) {
                    showMessage(response.message, response.success ? 'success' : 'error', feedbackContainer, true);
                }
            },
            complete: function() {
                setTimeout(function(){
                    sendedForm = false;
                }, 400);
            }
        })
    }
}