
var menuAnimating = false;
var mapOptions = null;
var google_map_loaded = false;
var docready = false;
var fontsRendered = true;
var cssLoaded = true;
var windowWidth = 0;
var blogImages = [];
var articleImages = {};
var sendedAjaxForm = false;

var ajax_request_running = false;

$(document).ready(function() {
	$('.mobile-search-btn').on('click', function () {
		$(this).toggleClass('opened');
		$('.main-header .search-box').toggleClass('opened');
	});

	$('section.service-section .submenu-title').click(function() {
		$(this).closest('.submenu-box').toggleClass('submenu-active');
	});

	$('[data-purpose="remodal-open"]').on('click', function() {
		var remodalId = $(this).data('target');
		if (!remodalId) {
			return;
		}

		var remodalTarget = $('[data-remodal-id="'+remodalId+'"]');
		if (remodalTarget.length === 1) {
			remodalTarget.remodal().open();
		}
	});

	$('#gototop').click(function(e) {
		var scroll = $(document).scrollTop();
		$('html, body').animate({scrollTop: 0}, Math.min(1000, Math.round(scroll / 100) * 50));
	});

	//create js hook links
	$('[data-link]').on('click', function() {
		var to = $(this).data('link');
		var $linked_item = $('#' + to);
		if($linked_item.length > 0) {
			$('html, body').animate({
		        scrollTop: $linked_item.offset().top
		    }, 500);
		}
	});

	$('.mailtolink').click(function(e) {
		var th = $(this);
		if (th.attr('href') == 'javascript:void(0)') {
			// e.preventDefault();
			var hidden = th.children('.emailhidden');
			th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
		}
	});

	$('select').wrap('<span class="custom-select"></span>');

	// $('.table-section table').stackcolumns();
	$('.article-content table, section.content-section table').wrap('<div class="table-overflow"></div>');

    var ua = window.navigator.userAgent;
    var nav_protocol = 'https://maps.google.com/?daddr=';
    if( ua.indexOf('Mac') > 0){
        nav_protocol = 'https://maps.apple.com/?daddr=';
    }

    $('.nav-button').click(function(){
        $(this).attr('href', nav_protocol+$(this).data("address")).attr('target', '_blank');
    });

    $('.tooltip').each(function(ind, el) {
    	var th = $(el);
    	createTooltip(th);
    });

    //Gógöl events
    $('.event-trigger').click(function() {
    	var th = $(this);
    	var category = th.data('event-category') || '';
    	var action = th.data('event-action') || 'Hívás';
    	var label = th.data('event-label') || '';
		var event_name = th.data('event-name') || category;

		if (typeof gtag !== 'undefined') {
			gtag('event', event_name, {
				event_category: category,
				event_action: action,
				event_label: label
			})
		}

    	if (typeof ga != 'undefined' && category) {
    		ga('send', 'event', category, action, label);
    	}
    });

    $('.facebook-trigger').click(function() {
    	var th = $(this);
    	var type = th.data('fbq-type') || '';

    	if (typeof fbq != 'undefined' && type) {
    		fbq('track', type);
    	}
    });

    $('.accordion').each(function(ind, el) {

    	var options = {};
    	var th = $(el);

    	if (th.data('single-active-item') != undefined) options.singleActiveItem = true;
    	if (th.data('active-index') != undefined) options.activeIndex = th.data('active-index');

    	new Accordion(th, options);
    });
		$('form.ajax-form').on('submit', function(e){
			e.preventDefault();
		});

    $(document).on('click', '.ajax-form .form-submit', function(e) {
		e.preventDefault();
    	// if (ajax_request_running) return;
    	ajax_request_running = true;

    	var th = $(this).closest('form');
    	var action = th.attr('data-action');
    	if (action == undefined) return;

		th.find('.invalid').removeClass('invalid');
		th.find('.submit-container').addClass('disabled');
		th.find('.svg-message-error').remove();

		var messageContainer = th.find('[data-purpose="feedback"]');
		if (!messageContainer.length) messageContainer = undefined;

		$.post(ajax_controller + action, th.serialize(), function(result) {

			if (result.message) {
				showMessage(result.message, result.success ? 'success' : 'error', messageContainer);
			}
			
			if (result.errors) {
				for (var i in result.errors) {
					var field = th.find('[name="' + result.errors[i] + '"]');
					if (field.length) {
						fieldCont = field.closest('.input-cont');
						fieldCont.addClass('invalid');
						field.addClass('invalid');
						if (fieldCont.hasClass('indicate-errors')) {
							fieldCont.append(svg('message-error'));
						}
					}
				}
			}
			if (result.success) {
				if (!result.keep_data) {
					th.find('input[type="hidden"]').val('');
					th.get(0).reset();
				}
				th.trigger('pw.formsuccess');
			}
			if (result.redirect) {
				if (result.redirect === 'refresh') {
					window.location.reload(true);
				}
				else {
					window.location = result.redirect;
				}
			}
			else {
				th.find('.submit-container').removeClass('disabled');
			}

			ajax_request_running = false;

		}, 'json');

		return false;
    });


    $('figure.image-in-article').each(function(ind, el) {
		var th = $(el);
		blogImages.push({src: th.data('full-url')});
	}).click(function() {
		var ind = $('figure.image-in-article').index(this);
		$(this).lightGallery({
			dynamic: true,
			dynamicEl: blogImages,
			download: false,
			hash:false,
			thumbnail: false, //kell, ha nincs thumbnail!!
			index: ind,
			galleryId: ind, //kell, ha több van egy oldalon!!
			mode: 'lg-fade' //ha 2 db slide van, a lapozásban van egy boogie, rossz a mozgás iránya
		});
	});

	$('.messenger-outer .icon').click(function(){
		if(!$('.messenger-outer').hasClass('opened')){
			$('.messenger-outer').addClass('opened');
		}
		else{
			$('.messenger-outer').removeClass('opened');
		}
	});


    $(document).on('pw.pageready', function() {

    	var hash = window.location.hash;
    	if (hash && $(hash).length) {
    		$('html, body').animate({scrollTop: $(hash).offset().top}, 100);
    	}

    	$('.lazy-background').each(function(ind, el) {
    		var th = $(el);
    		loadBackground(th, function(image) {
    			if (th.hasClass('parallax')) {
    				// new Parallax(th);
    				th.parallax('50%', 0.3);
    			}
    			th.addClass('background-showing');
    		});
    	});

    	new EffectMachinator({
    		selector: 'section.sectioneffect',
	        minWidth: 1025,
	        delayOffset: 100
    	});

    	$('.equal-heights').each(function(ind, el) {
    		new EqualHeights($(el));
    	});

    });

	$('#streetSearchInput').autocomplete({
		minLength:2,
		source: function(request, response) {
			var result = [];
			var term = request.term.trim();

			$.ajax({
				url:'ajax/getStreetStep',
				dataType:'json',
				type:'post',
				data: {term:term},
				success:function(resp) {
					for (var i = 0; i < resp.items.length; i++) {
						result.push({
							value: resp.items[i].value,
							label: resp.items[i].label,
							zone: resp.items[i].zone
						});
					}

					response(result);
				}
			});
		},
		appendTo: $('#streetSearchInput').parent(),
		select: function(event, ui) {
			if (ui.item && typeof ui.item.zone !== 'undefined') {
				$('.zone-container').html('<span class="zone-item">'+ui.item.zone+'</span>');
			}
		},
		search: function(evt, ui) {
			$('.zone-container').html('');
		}
	});

	$('.accordion-list').each(function(ind, el) {
		var options = {};
		var th = $(el);

		if (th.data('single-active-item') != undefined) options.singleActiveItem = true;
		if (th.data('active-index') != undefined) options.activeIndex = th.data('active-index');
		if (th.data('from-width') != undefined) options.fromWidth = parseInt(th.data('from-width'));
		
		new Accordion(th, options);
	});

	$('.article-gallery-item').on('click', function() {
		openArticleGallery($(this));
	});

	$(".article-gallery-img").click(function(){
		var galleryId = $(this).data("gallery-id");
		if(typeof galleryImages[galleryId] != "undefined"){
			var index = $(this).data("index");
			var images = galleryImages[galleryId];
			$(this).lightGallery({
				dynamic: true,
				hash:false,
				dynamicEl: images,
				index:index,
				download:false
			});
		}
	});

	$('[data-purpose="tab-container"]').each(function() {
		var container = $(this);

		$(this).find('[data-purpose="tab-label"]').on('click', function() {
			if ($(this).hasClass('active')) {
				return;
			}
			
			var tab = $(this).data('tab');
			container.find('[data-purpose="tab-content"]').removeClass('active');
			container.find('[data-purpose="tab-label"]').removeClass('active');
			
			$(this).addClass('active');
			container.find('[data-purpose="tab-content"][data-tab="'+ tab +'"]').addClass('active');
		}).first().click();
	});

	$('[data-purpose="archive-select"]').on('change', function() {
		var option = $(this).find('option:selected');
		if (option && option.length === 1) {
			var url = option.data('url');
			if (url) {
				window.location.href = url;
			}
		}
	});

	$(document).on('closing', '.zip-selector-remodal', function() {
		saveCustomerZip('', '', 'popup', true);
	});

	$('[data-purpose="zip-modal-field"]').on('keypress', function(evt) {
		var keyCode = evt.keyCode ? evt.keyCode : evt.which;
		if (13 === keyCode) {
			sendCustomerZip($(this).data('type'));
		}
	});

	$('[data-purpose="zip-modal-button"]').on('click', function(evt) {
		evt.preventDefault();
		sendCustomerZip($(this).data('type'));
	});

	$('#zipModifyForm').on('submit', function(evt) {
		evt.preventDefault();

		var form = $('#zipModifyForm');
		var zip = form.find('[name="zip"]').val();
		var type = form.find('input[type="radio"]:checked').val();
		
		saveCustomerZip(zip, type, 'modify');
	});

	$('#zipModifyForm .button').on('click', function() {
		$('#zipModifyForm').submit();
	});

	if (typeof wasteCalendarStreets !== 'undefined') {
		$('#wasteCalendarStreets').on('keypress', function(evt) {
			var keyCode = evt.keyCode ? evt.keyCode : evt.which;
			if (13 === keyCode) {
				drawStreetTags($(this).val(), 'waste-calendar');
			}
		});

		$('#wasteCalendarStreets').autocomplete({
			minLength: 3,
			source: function(request, response) {
				response(filterWasteCalendarStreets(request.term));
			},
			appendTo: $('#wasteCalendarStreets').parent(),
			select: function(event, ui) {
				var item = ui.item;

				showWasteCalendarInfo(ui.item);
			},
			search: function(event, ui) {
				$('.waste-calendar-form .result-content').removeClass('active');
			}
		});

		$('#wasteCalendarSearchBtn').on('click', function(evt){
			evt.preventDefault();
			var street = $('#wasteCalendarStreets').val();
			if (street.trim()) {
				drawStreetTags(street, 'waste-calendar');
			}
		});
	}

	$(document).on('click', '.street-tag', function(event) {
		var type = $(event.target).hasClass('street-tag') 
			? $(event.target).data('type') 
			: $(event.target).parents('.street-tag').data('type');
		
		if (type === 'waste-calendar') {
			var street = $(this).data('street');
			var streetOption = wasteCalendarStreets.find(function(item) {
				return (item.label).toLowerCase() === street.toLowerCase();
			});

			showWasteCalendarInfo(streetOption);
		}
	});

	$('[data-purpose="scroll-to-element"]').on('click', function() {
		var target = $(this).data('target');
		if (target.trim()) {
			var offset = $(this).data('offset');
			if (isNaN(parseInt(offset))) {
				switch (offset) {
					case 'section-height':
						offset = $(this).parents('section').outerHeight(true);
						break;
					default:
						offset = 0;
						break;
				}
			}
			
			scrollToElement(target, parseInt(offset));
		}
	});

	$('#forgotPassForm').on('submit', function(e) {
		e.preventDefault();
		
		if (!sendedAjaxForm) {
			var form = $(this);
			var feedbackBox = form.find('.form-feedback');
			sendedAjaxForm = true;

			$.ajax({
				url: form.attr('action'),
				dataType: 'json',
				type: 'POST',
				beforeSend: function() {
					form.find('.has-error').removeClass('has-error')
					feedbackBox.html('');
					feedbackBox.removeClass('active error-box');
				},
				data: form.serialize(),
				success: function (response) {
					var className = response.success ? 'success-box' : 'error-box';
					
					feedbackBox.addClass('active '+className);
					feedbackBox.html(response.message);
					if (!response.success) {
						form.find('.field').addClass('has-error');
					}

					setTimeout(function() {
						feedbackBox.removeClass('active');
					}, 3000);

					setTimeout(function() {
						sendedAjaxForm = false;
					}, 1000);

					if (response.success) {
						form[0].reset();
						if (typeof response.url !== 'undefined') {
							setTimeout(function() {
								document.location.href = response.url;
							}, 3000);
						}
					}
				}
			})
		}
	});

	$('#search-button').on('click', function() {
		var form = $(this).parents('form');
		if (form.length > 0) {
			form[0].submit();
		}
	});
});

$(window).load(function() {

	var resizeHandler = debounce(function() {
		__doresize();
    }, 250);

	$(window).resize(resizeHandler);
	$(window).on('scrollstart', function(){
		$('#gototop').removeClass('gototop-visible');
		setTimeout(function() {
			$('#gototop').css('display', 'none');
		}, 300);
	}).on('scrollstop', function(){

		var scroll = $(document).scrollTop();
		var gototop = $('#gototop');
		if (scroll > 100) {
			var fh = $('[data-role="gototop-anchor"]:last').outerHeight(true) + 10;
			var footerpart = scroll + $(window).height() - $(document).height() + fh;
		 	if(footerpart <= 10) {
		        // gototop.css("position","fixed");    //resetting it
		        gototop.css("bottom", "10px"); //resetting it
	    	}
	        else {
		       	gototop.css("bottom", footerpart + "px");
		    }
			gototop.css('display', 'block').addClass('gototop-visible');
		}
		else {
		}
	});

	__doresize();

});


function __doresize() {

	$(window).trigger('scrollstop');
}


function __closeAllToggle() {
	// if ($('#mobile-menu').hasClass('opened'))
	// 	$('#mobile-toggle-cont').click().removeClass('tcon-transform');

	$('li.has-sub.open > a').click();

	// menuAnimating = false;
	// $('.custom-select.active').removeClass('active');
	// $('.custom-select.with-button .clicked').removeClass('clicked');
}


function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}


function googleMapsLoaded() {
	mapOptions = {
		zoom: 12,
		center: new google.maps.LatLng(47.5288921, 21.631853),
		scrollwheel: false,
		disableDefaultUI: true,
		zoomControl: true,
		streetViewControl: true,
		fullscreenControl: true,
		styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#46bcec"},{"visibility":"on"}]}]
	};
	google_map_loaded = true;
	$(document).trigger('aksd.mapinit');
}

function loadBackground(elem, onload) {
	if (typeof elem == 'string') elem = $(elem);

	var img = elem.data('src');
	if (img) {

		var image = $('<img />').one('load', function() {

			elem.css('backgroundImage', 'url(' + img + ')').addClass('background-loaded');
			if (typeof onload == 'function') onload(image);
		}).attr('src', img + '?r=' + Math.random() * 12345);
	}
	else if (typeof onload == 'function') onload();

}

function loadJs(url, implementationCode){

    var scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = implementationCode;
    scriptTag.onreadystatechange = implementationCode;

    document.body.appendChild(scriptTag);
};


function scrollToElement(selector, offset)
{
	if (isNaN(offset)) {
		offset = 0;
	}
	if($(selector).length > 0){
		$('html, body').animate({
			scrollTop: $(selector).offset().top - offset - 10
		}, 700);
	}
}


function detect_webgl_support(){

    // Create canvas element. The canvas is not added to the
    // document itself, so it is never displayed in the
    // browser window.
    var canvas = document.createElement("canvas");
    // Get WebGLRenderingContext from canvas element.
    var gl = canvas.getContext("webgl")
      || canvas.getContext("experimental-webgl");
    // Report the result.
    if (gl && gl instanceof WebGLRenderingContext) {
      return true;
    }

    return false;
}


function checkConcurrentLoadResults() {
	if (cssLoaded && fontsRendered) {
    	docready = true;

			setTimeout(function(){
				$(document).trigger('pw.preloaderremoved');
			}, 300);

	    checkMapReady();
	    $(document).trigger('pw.pageready');
	}
}


function checkMapReady() {
	if (docready && google_map_loaded) {
		$(document).trigger('pw.mapinit');
	}
}

function svg(id) {
	return '<span class="svg svg-' + id + '"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}

function showMessage(msg, type, container, changeVisibility){
	if (type == undefined) type = 'error';
	if (container && !container.length) container = undefined;

	if (changeVisibility === undefined) {
		changeVisibility = container.hasClass('feedback-box');
	}

	if (container.hasClass('feedback-box')) {
		var feedbackClass = type+'-box';
		container.removeClass('error-box');
		container.removeClass('success-box');
		container.addClass(feedbackClass);
	}

	var number = Math.floor(Math.random() * (999999 - 100000) + 100000);
	var message = $('<div />', {
		'class': 'generated-message',
		'data-number': number
	}).addClass('message ' + type).html(svg('message-' + type) + '<span class="message-cont">' + msg + '</span>');
	var len = msg.length * 100;
	
	var scroll = false;

	if (!container) {
		container = $('.site-messages');
	}
	else if (!isElementInViewport(container)) {
		scroll = true;
	}

	var removeMe = function(){
		message.removeClass('showing');
		
		setTimeout(function() {
			var number = message.data('number');
			if (number) {
				$('.generated-message[data-number="'+ number +'"]').remove();
			} else {
				message.remove();
			}
			
			if (changeVisibility) {
				container.removeClass('active');
			}
		}, 300);
	};

	message.prependTo(container);
	message.addClass('showing').click(function() {
		removeMe();
	});

	if (changeVisibility) {
		container.addClass('active');
	}

	if (scroll) {
		var top = container.offset().top - $(window).height() + container.outerHeight(true);
		$('html, body').animate({scrollTop: top}, 300);
	}

	setTimeout(removeMe, len);
}

function showInputFeedback(msg, type, field){
	if (type == undefined) type = 'error';
	var container = $('[name="' + field + '"]').closest('.input-cont');
	if (!$(container).length) return;

	var message = $('<div />').addClass('message ' + type).html(svg('message-' + type) + '<span class="message-cont">' + msg + '</span>');
	var len = msg.length * 100;

	var removeMe = function(){
		message.removeClass('showing');
		setTimeout(function() {
			message.remove();
		}, 300);
	};

	message.prependTo(container);
	message.addClass('showing').click(function() {
		removeMe();
	});

	setTimeout(removeMe, len);
}

function translate(elements, callback){

    if(typeof callback === 'undefined')
    {
        callback = function() {};
    }

    var ret = {};

    if (typeof elements == 'object') {

    	var req = [];
    	for (var a in elements) {
    		if (typeof dictionary[elements[a]] == 'undefined') {
    			req.push(elements[a]);
    		}
    		else {
    			ret[elements[a]] = dictionary[elements[a]];
    		}
    	}

    	if (req.length) {
    		$.post(ajax_controller + '/translate', {items: req}, function(rData) {

	        	if (rData) {
		        	for (var i in rData) {
		            	dictionary[i] = ret[i] = rData[i];
		        	}
	        	}

	            callback.call(undefined, ret);

	        }, 'json');
    	}
    	else {
			callback.call(undefined, ret);
    	}
    }

};

function createTooltip(th) {
	var options = {
		maxWidth: '70%',
		tooltipHover: true,
		titleBackground: '',
		background: ''
	};

	if (th.data('title')) {
		options.useTitle = true;
		options.titleContent = th.data('title');
	}

	if (th.data('position')) {
		options.position = th.data('position');
	}

	if (th.data('width')) {
		options.width = th.data('width');
	}

	var helper = th.parent().find('.tipso');
	if (helper.length) {
		options.content = helper.html();
	}

	th.tipso(options);
}

function isElementInViewport (el) {

    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
}

function openArticleGallery(item) {
	var galleryId = item.data('gallery-id');
	var images = typeof articleImages[galleryId] !== 'undefined' ? articleImages[galleryId] : [];
	
	if (images.length > 0) {
		var index = item.data('index');

		item.lightGallery({
			dynamic: true,
			dynamicEl: images,
			download: false,
			hash:false,
			thumbnail: false, //kell, ha nincs thumbnail!!
			index: index,
			galleryId: galleryId, //kell, ha több van egy oldalon!!
			mode: 'lg-fade' //ha 2 db slide van, a lapozásban van egy boogie, rossz a mozgás iránya
		});
	}
}

function sendCustomerZip(type)
{
	var field = $('[data-purpose="zip-modal-field"][data-type="'+type+'"]');
	saveCustomerZip(field.val(), type, 'popup');
}

function saveCustomerZip(zip, type, container, close)
{
	var form = null;
	if (undefined === close) {
		close = false;
	}

	if (container === 'modify') {
		form = $('#zipModifyForm');
	}

	$.ajax({
		url: ajax_controller + 'save_customer_zip',
		dataType: 'json',
		type: 'post',
		data: {
			zip: zip.trim(),
			type: type,
			close: close
		},
		beforeSend: function() {
			if (container === 'popup') {
				$('.zip-selector-remodal').find('.has-error').removeClass('has-error');
				$('.zip-selector-remodal').find('.error-content').html('');
				$('.zip-selector-remodal').find('.error-content').removeClass('active');
			}

			if (container === 'modify') {
				form.find('.error-content').html('');
				form.find('.has-error').removeClass('has-error');
			}
		},
		success: function(response) {
			if (response.success) {
				document.location.reload();
			}

			if (!response.success) {
				if (container === 'popup') {
					$('.zip-selector-remodal').find('.field[data-type="'+ type +'"]').addClass('has-error');
					$('.zip-selector-remodal').find('[data-purpose="' + type + '-error"]').addClass('active');
					$('.zip-selector-remodal').find('[data-purpose="' + type + '-error"]').html(response.errors.zip);
				}

				if (container === 'modify') {
					$.each(response.errors, function(field, message) {
						form.find('[name="'+ field +'"]').addClass('has-error');
						var content = form.find('.error-content[data-field="' + field + '"]');
						if (content.length > 0) {
							content.html(message);
						}
					});
				}
			}
		}
	})
}

function showWasteCalendarInfo(street) {
	if (typeof street.options !== 'object') {
		return;
	}
	$('.waste-calendar-form .street-tags-container').removeClass('active');
	
	var options = Object.keys(street.options);
	for (var i = 0; i < options.length; i++) {
		var type = options[i];
		var value = typeof emptyWasteOptionString !== 'undefined' && emptyWasteOptionString ? emptyWasteOptionString : '-';
		if (street.options[type].length > 0) {
			value = street.options[type].join('<br/>');
		}

		$('.waste-calendar-form [data-purpose="waste-calendar-result"][data-type="'+ type +'"]').html(value);
	}
	
	$('#wasteCalendarStreets').val(street.label);
	$('.waste-calendar-form .result-content').addClass('active');
}

function drawStreetTags(street, type)
{
	$('#wasteCalendarStreets').autocomplete('close');

	$('.waste-calendar-form .street-tags-container').removeClass('active');
	$('.waste-calendar-form .result-content').removeClass('active');

	street = street.trim();
	if (street.length < 3) {
		return;
	}
	
	if (type === 'waste-calendar') {
		var streets = filterWasteCalendarStreets(street);
		$('.waste-calendar-form .result-content').removeClass('acitve');

		if (streets.length > 0) {
			$('.waste-calendar-form .street-tags-container').html('');
			$('.waste-calendar-form .street-tags-container').addClass('active');
			
			streets.forEach(function(item) {
				$('.waste-calendar-form .street-tags-container').append('<span class="street-tag" data-type="waste-calendar" data-street="'+ item.label +'">'+svg('search-icon')+'<span class="label">'+ item.label +'</span></span>');
			});
		}
	}
}

function filterWasteCalendarStreets(street) {
	return wasteCalendarStreets.filter(function(item) {
		var valid = false;
		item.searchValues.forEach(function(value) {
			if (value.indexOf((street.trim()).toLowerCase()) >= 0) {
				valid = true;
			}
		});

		return valid;
	});
}