$(document).ready(function() {
	if ($('#notificationSlider').length > 0) {
        new Swiper('#notificationSlider', {
            effect:'fade',
            height:'auto',
            speed: 800,
            autoplay: {
                delay: 7000
            },
            fadeEffect: {
                crossFade: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {   
                slideChange: function() {
                    var currentSlide = this.realIndex + 1;
                    $(this.$el).find('.current-page').html(currentSlide);
                },
                beforeInit: function() {
                    var allSlidesCounter = this.wrapperEl.querySelectorAll('.swiper-slide').length;
                    if (allSlidesCounter > 1) {
                        $(this.$el).find('.all-page').html(allSlidesCounter);
                        return;
                    }

                    $(this.wrapperEl).find('.numbers').hide();
                }
            }
        })
    }
});