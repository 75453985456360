$(document).ready(function(){
    $('[data-purpose="support-option-radio"]').on('change', function(){
        var type = $(this).data('type');

        if (type === 'contact') {
            changeContactTypeRadio($(this));
        }
    });

    $('#bookingDate').pickadate({
        firstDay: 1,
        min: true,
        hiddenName: true,
        formatSubmit: 'yyyy-mm-dd',
        format: 'yyyy. mmmm dd.',
        editable: false,
        today: '',
        clear: '',
        close: '',
        onSet: function(context) {
            if (typeof context.select !== 'undefined') {
                var date = new Date(context.select);
                var month = date.getMonth();
                loadSupportTimeOptions(date.getFullYear() + '-' + (month+1) + '-' + date.getDate());
            }
        }
    });

    $('#supportForm').on('submit', function(e){
        e.preventDefault();
        
        sendSupportForm($(this));
    });

    $('[data-purpose="support-form-btn"').on('click', function(){
        $(this).parents('form').submit();
    });

});

var openedDateSelector = false;
function changeContactTypeRadio(input) {
    var value = input.val();
    if (value === 'personal' || openedDateSelector) {
        openedDateSelector = true;
        $('[data-purpose="datetime-selector"]').slideToggle();
    }
}

function loadSupportTimeOptions(date) {
    $('#bookingTime').find('option').remove();

    $.ajax({
        url: ajax_controller+'get_available_support_time',
        dataType:'json',
        type:'post',
        data: {
            date: date
        },
        success: function(response) {
            $('#bookingTime').append('<option>Kérem válasszon</option>');
            if (response.options && response.options.length > 0) {
                for (var i = 0; i < response.options.length; i++) {
                    $('#bookingTime').append('<option value="'+response.options[i]+'">'+response.options[i]+'</option>');
                }
            }
        }
    });
}

var sendedForm = false;
function sendSupportForm(form) {
   if (!sendedForm) {
    sendedForm = true;

    $.ajax({
        url:ajax_controller+'send_support_form',
        dataType:'json',
        type:'post',
        data: form.serialize(),
        beforeSend: function() {
            form.find('[data-purpose="error-content"]').removeClass('active');
            form.find('.invalid').removeClass('invalid');
        },
        success: function(response) {
            var feedbackContainer = form.find('[data-purpose="feedback"]');
            
            if (response.success) {
                if (openedDateSelector) {
                    openedDateSelector = false;
                    $('[data-purpose="datetime-selector"]').slideToggle();
                }
                $('#bookingTime').find('option').remove();
                form.get(0).reset();
            } else {
                var fields = Object.keys(response.errors);
                if (fields.length > 0) {
                    $.each(response.errors, function(field, msg){
                        var input = form.find('[name="'+field+'"]');
                        if (input.length && input.parent().hasClass('input-cont')) {
                            input.parent().addClass('invalid');
                            input.addClass('invalid');
                        }
                        if (msg.trim()) {
                            var errorContainer = $('[data-purpose="error-content"][data-field="'+field+'"]');
                            if (errorContainer.length) {
                                errorContainer.html(msg);
                                errorContainer.addClass('active');
                            }
                        }
                    });
                }
            }

            if (feedbackContainer && response.message) {
                showMessage(response.message, response.success ? 'success' : 'error', feedbackContainer);
            }
        },
        complete: function() {
            setTimeout(function(){
                sendedForm = false;
            }, 400);
        }
    })
   }
}